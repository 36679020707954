<template>
  <div class="login-container">
    <el-form class="login-form" :model="loginForm" :rules="loginRules" ref="loginFromRef">
      <div class="title-container">
        <h3 class="title">太阳能光伏管理系统</h3>
      </div>
      <el-form-item prop="username">
        <el-input type="text" name="username" placeholder="请输入用户名" v-model="loginForm.username" :prefix-icon="User" />
      </el-form-item>

      <el-form-item prop="password">
        <el-input type="password" name="password" :prefix-icon="Lock" placeholder="请输入密码"
          v-model="loginForm.password" />
      </el-form-item>
      <el-button type="primary" :loading="loading" style="width: 100%" @click="handleLogin">
        登录
      </el-button>
    </el-form>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { User, Lock } from '@element-plus/icons-vue'
const loginForm = ref({
  username: '',
  password: '',
});

const loading = ref(false);
const loginFromRef = ref(null);
const store = useStore();
const router = useRouter();

const validatePassword = () =>  (rule, value, callback) => {
  if (value.length < 6) {
    callback(new Error('请输入正确的密码'));
  } else {
    callback();
  }
};


const loginRules = ref({
  username: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入用户名',
    },
  ],
  password: [
    {
      required: true,
      trigger: 'blur',
      validator: validatePassword(),
    },
  ],
});

const handleLogin = () => {
  loginFromRef.value.validate((valid) => {
    if (!valid) return;
    loading.value = true;
    store
      .dispatch('user/login', loginForm.value)
      .then(() => {
        loading.value = false;
        router.push('/');
      })
      .catch((err) => {
        console.log(err);
        loading.value = false;
      });
  });
};
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
$cursor: #fff;

.login-container {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: $bg;
  .login-form {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 360px;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>
